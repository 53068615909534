export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const theme = {
  breakpoints: Object.values(breakpoints).map((b) => `${b}px`),

  fonts: {
    serif: 'freight-display-pro, serif;',
    sans: 'sofia-pro, sans-serif;',
    script: 'EmmylouSignature, sans-serif',
    heading: 'inherit',
  },

  fontWeight: {
    bold: '600',
    normal: '400',
    light: '100',
    heading: 'inherit',
  },
  lineHeights: {
    heading: 1.25,
  },
  fontSizes: {
    // Text sizes
    tinyM: '10px',
    smallM: '14px',
    bodyM: '14px',
    heroItineraryTextM: '26px',
    blockquoteM: '18px',
    introScriptM: '100px',
    introM: '30px',
    tiny: '10px',
    small: '14px',
    body: '16px',
    heroItineraryText: '36px',
    blockquote: '20px',
    intro: '40px',
    // Heading sizes
    heroItineraryS: '50px',
    h7M: '12px',
    h6M: '16px',
    h5M: '22px',
    h4M: '28px',
    h3M: '26px',
    h2M: '32px',
    h1M: '32px',
    introScript: '120px',
    h2ScriptM: '100px',
    h3ScriptM: '80px',
    destinationScriptM: '63px',
    heroM: '61px',
    heroItineraryM: '80px',
    h7: '12px',
    h6: '16px',
    h5: '22px',
    h4: '32px',
    h3: '36px',
    h2: '50px',
    h1: '58px',
    destinationScript: '120px',
    h2Script: '120px',
    h3Script: '120px',
    heroItinerary: '100px',
    hero: '122px',
    heading: 'inherit',
  },

  letterSpacing: {
    tiny: '0.05em',
    small: '0.1em',
    large: '0.15em',
  },

  space: Array(33)
    .fill(0)
    .map((v, i) => i * 8),

  colors: {
    baseWhite: 'white',
    baseBlack: 'black',
    baseGray: '#969696',
    baseGrayLight: '#E8E9EB',
    baseGrayDark: '#2C2C2C',
    textDark: '#424242',
    textLight: '#ffffff',
    brandBeige: '#F6F3EE',
    brandBeigeLight: '#FBF9F7',
    brandRed: '#BA3139',
    brandRedDark: '#74171D',
    borderGray: '#D7D3CC',
    borderGrayDark: '#676767',

    // Status
    statusWarning: '#ffc107',
    statusSuccess: '#28a745',
    statusInfo: '#17a2b8',
    statusError: '#BA3139',
  },

  speed: {
    default: '0.3s',
    slow: '0.5s',
    fast: '0.1s',
    slowest: '5s',
  },

  opacity: {
    full: 1,
    medium: 0.7,
    feint: 0.3,
    opaque: 0,
  },

  icons: {
    search: 'seach',
    close: 'close',
    play: 'play',
    menu: 'menu',
    inquire: 'inquire',
    down: 'down',
  },
}

export default theme
