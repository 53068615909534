import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from '../common/theme'
import { Box } from 'rebass/styled-components'

const StyledContent = styled(Box)`
  img {
    width: inherit;
    max-width: 100%;
    height: auto;
  }
  p {
    margin-bottom: ${theme.space[2]}px;
    text-align: justify;
    a {
      word-break: break-word;
    }
  }
  a {
    color: inherit;
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  b,
  strong {
    font-weight: ${theme.fontWeight.bold};
  }
  em,
  i {
    font-style: italic;
    &.icon {
      font-style: normal;
    }
  }
  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    margin-bottom: ${theme.space[2]}px;
  }

  ol > li {
    list-style-type: decimal;
  }
  ul > li {
    list-style-type: disc;
  }
  small {
    font-size: ${theme.fontSizes.small};
  }
  hr {
    border-style: solid;
    border-width: 0px;
    border-color: ${theme.colors.baseGrayLight};
    margin-block-start: ${theme.space[4]}px;
    margin-block-end: ${theme.space[4]}px;
  }
  blockquote {
    margin: 0;
    padding: 0;
    margin-top: ${theme.space[4]}px;
    margin-bottom: ${theme.space[4]}px;
    margin-left: ${theme.space[4]}px;
    margin-right: ${theme.space[4]}px;
    em,
    i {
      font-family: ${theme.fonts.script};
      font-size: 200%;
      line-height: 0;
      &.icon {
        font-family: inherit;
      }
    }
  }
  cite {
    font-style: italic;
  }
  address {
    font-style: normal;
  }
  figcaption {
    margin-top: ${theme.space[1]}px;
    font-size: ${theme.fontSizes.small};
  }
  h6:not(.customHeading) {
    font-size: ${theme.fontSizes.h6M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h6};
    }
  }
  h5:not(.customHeading) {
    font-size: ${theme.fontSizes.h5M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h5};
    }
  }
  h4:not(.customHeading) {
    font-size: ${theme.fontSizes.h4M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h4};
    }
  }
  h3:not(.customHeading) {
    font-size: ${theme.fontSizes.h3M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h3};
    }
  }
  h2:not(.customHeading) {
    font-size: ${theme.fontSizes.h2M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h2};
    }
  }
  h1:not(.customHeading) {
    font-size: ${theme.fontSizes.h1M};
    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.fontSizes.h1};
    }
  }
  h1,
  h2,
  h3 {
    em,
    i {
      font-family: ${theme.fonts.script};
      font-size: 250%;
      line-height: 0;
      &.icon {
        font-family: inherit;
      }
    }
  }
  h4,
  h5,
  h6 {
    em,
    i {
      font-family: ${theme.fonts.script};
      font-size: 350%;
      line-height: 0;
      &.icon {
        font-family: inherit;
      }
    }
  }
`

const Content = (props) => {
  const { children } = props

  return <StyledContent>{children}</StyledContent>
}

Content.propTypes = {
  children: PropTypes.node,
}

Content.defaultProps = {
  children: null,
}

export default Content
