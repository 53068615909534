import React from 'react'
import { graphql } from 'gatsby'
import RichTextRenderer from '../components/RichTextRenderer'
import { Box } from 'rebass/styled-components'
import { Heading } from '@roar/components'
import ImageWrapper from './ImageWrapper'
import { mapper } from '../common/utils'
import Content from '@roar/components/src/components/Content/Content'
import { Animated, slideUpAnimation, ScriptContainer } from '@roar/components'

const CONTENT_MAP = {
  id: ['id'],
  headline: ['headline'],
  content: ['content'],
  image: ['image'],
  animation: (value) => {
    const { style } = value
    if (style && style.animation) {
      return style.animation
    } else {
      return { shouldAnimate: true, variants: slideUpAnimation }
    }
  },
  style: ['style'],
}

const ContentGenericWrapper = (props) => {
  const { showHeading = true, ...rest } = props
  const { id, animation, headline, content, image, style } = mapper(rest, CONTENT_MAP)

  return (
    <Box id={id} className="contentGenericWrapper" {...style} sx={{ width: ['100%'] }}>
      <Animated {...animation}>
        <Box>
          {showHeading && headline && (
            <ScriptContainer>
              <Heading size="h3" mb={[4]} html={headline} />
            </ScriptContainer>
          )}
          {content && (
            <Content>
              <RichTextRenderer content={content} />
            </Content>
          )}
          {image && <ImageWrapper image={image} altText={headline && headline} />}
        </Box>
      </Animated>
    </Box>
  )
}

export default ContentGenericWrapper

export const contentGeneric = graphql`
  fragment ContentGeneric on ContentfulContentGeneric {
    id
    contentful_id
    name
    headline
    __typename
    content {
      raw
      references {
        __typename
        ... on ContentfulVideo {
          ...Video
        }
        ... on ContentfulLinks {
          ...LinkFields
        }
        ... on ContentfulImage {
          contentful_id
          altText
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ... on ContentfulForm {
          id
          name
        }
        ... on ContentfulAsset {
          contentful_id
          title
          gatsbyImageData(layout: FULL_WIDTH)
          description
        }
        ... on ContentfulDestinations {
          contentful_id
          slug
        }
        ... on ContentfulPosts {
          contentful_id
          slug
          category {
            id
            name
            slug
            headline
          }
          postType {
            id
            name
            name
            headline
          }
          date(formatString: "MMMM D, YYYY")
          year: date(formatString: "YYYY")
          month: date(formatString: "MM")
        }
        ... on ContentfulPage {
          contentful_id
          slug
        }
        ... on ContentfulHeading {
          ...HeadingFields
        }
        ... on ContentfulQuote {
          ...Quote
        }
      }
    }
    image {
      gatsbyImageData(layout: CONSTRAINED, width: 960, quality: 90)
    }
    pressImage: image {
      gatsbyImageData(layout: CONSTRAINED, width: 200, quality: 90)
    }
    style {
      id
      pb
      pl
      pr
      pt
      bg
      textAlign
      alignItems
      justifyContent
      display
      animation {
        shouldAnimate
        variants
      }
    }
    links {
      ... on ContentfulPosts {
        ...PostArchive
      }
    }
  }
`
